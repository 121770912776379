import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../../layouts/AppLayout/AppLayout';
import { Col, Row, Container } from 'react-bootstrap';

function BlogDetails() {
  const { articleId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/api/medium-post?articleId=${articleId}`);
        if (!response.ok) throw new Error('Failed to fetch post');
        const data = await response.json();
        setPost(data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchPost();
  }, [articleId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <AppLayout title={post.title} rootClass="layout-1">
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h1>{post.title}</h1>
            {post.virtuals.previewImage.imageId && (
              <img
                src={`https://cdn-images-1.medium.com/max/800/${post.virtuals.previewImage.imageId}`}
                alt="Cover"
                className="img-fluid rounded my-4"
              />
            )}
            <div>{new Date(post.createdAt).toLocaleDateString()}</div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
            <div className="author-info mt-5 text-center">
              {post.author.imageId && (
                <img
                  src={`https://cdn-images-1.medium.com/fit/c/100/100/${post.author.imageId}`}
                  alt={post.author.name}
                  className="rounded-circle mb-2"
                  style={{ width: '100px', height: '100px' }}
                />
              )}
              <h5>{post.author.name}</h5>
              <p>{post.author.bio}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
}

export default BlogDetails;
