import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const PricingWrapper = styled.div`
  background: linear-gradient(135deg, #3F78E0 0%, #5c67f2 25%, #854fff 50%, #e85347 75%, #f4bd0e 100%);
  padding: 60px 40px;
  border-radius: 24px;
  margin: 40px 20px;
  position: relative;
  overflow: hidden;
`;

const GlassOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at top left, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
  pointer-events: none;
`;

const PricingCard = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  }
`;

const PricingTitle = styled.h3`
  color: ${props => props.isHighlighted ? 'transparent' : '#3F78E0'};
  font-weight: ${props => props.isHighlighted ? 'bold' : 'normal'};
  background-image: ${props => props.isHighlighted ? 'linear-gradient(135deg, #3F78E0 0%, #5c67f2 25%, #854fff 50%, #e85347 75%, #f4bd0e 100%)' : 'none'};
  background-clip: ${props => props.isHighlighted ? 'text' : 'none'};
  -webkit-background-clip: ${props => props.isHighlighted ? 'text' : 'none'};
  -webkit-text-fill-color: ${props => props.isHighlighted ? 'transparent' : 'inherit'};
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const FeatureItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:before {
    content: '→';
    color: #3F78E0;
    margin-right: 10px;
  }
`;

const CustomPricingCard = ({
  title,
  subtitle,
  features = [],
  extraFeatures = [],
  isHighlighted = false,
  isCustomPlan = false
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <PricingCard
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transform: isHovered ? 'translateY(-10px)' : 'none',
        boxShadow: isHovered ? '0 15px 40px rgba(0, 0, 0, 0.2)' : '0 10px 30px rgba(0, 0, 0, 0.1)'
      }}
    >
      <PricingTitle isHighlighted={isHighlighted}>{title}</PricingTitle>
      <p style={{ color: '#555', marginBottom: '20px' }}>{subtitle}</p>
      <div style={{ color: '#333', marginBottom: '10px' }}>
        <FeatureList>
          {!isCustomPlan && extraFeatures.map((feature, index) => (
            <FeatureItem key={`extra-${index}`} style={{ fontWeight: 'bold' }}>
              {feature}
            </FeatureItem>
          ))}
          {features.map((feature, index) => (
            <FeatureItem key={index}>
              {feature}
            </FeatureItem>
          ))}
        </FeatureList>
      </div>
    </PricingCard>
  );
};

export default function PricingContent() {
  return (
    <PricingWrapper>
      <GlassOverlay />
      <Row className="justify-content-center text-center">
        <Col xl={7}>
          <div className="nk-section-head mb-xl-7">
            <span className="d-inline-block fs-16 text-uppercase fw-bold mb-2" style={{ color: '#ffffff' }}>Plans</span>
            <h2 style={{ color: '#ffffff' }}>Choose Your Path to Success</h2>
            <p className="fs-20" style={{ color: '#ffffff' }}>
              Our tailored plans are designed to meet your specific conversion optimization needs.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-4">
          <CustomPricingCard
            title="Basic Plan"
            features={[
              "Testing (2 A/B Tests)",
              "CXL Certified Expert Review",
              "Site Usability Audit",
              "Technical Audit",
              "Creative Audit",
              "Hypothesis creation & new solution design",
              "Post-test analysis",
              "Customer Behaviour Analysis via Hotjar",
              "Problem identification & prioritization",
              "Funnel Optimization"
            ]}
          />
        </Col>
        <Col md={4} className="mb-4">
          <CustomPricingCard
            title="Growth Plan"
            isHighlighted={true}
            extraFeatures={[
              "Testing (4 A/B Tests)",
              "User Surveys",
              "Cart Abandonment Campaign",
              "Browse Recovery Campaign"
            ]}
            features={[
              "CXL Certified Expert Review",
              "Site Usability Audit",
              "Technical Audit",
              "Creative Audit",
              "Hypothesis creation & new solution design",
              "Post-test analysis",
              "Customer Behaviour Analysis via Hotjar",
              "Problem identification & prioritization",
              "Funnel Optimization",
            ]}
          />
        </Col>
        <Col md={4} className="mb-4">
          <CustomPricingCard
            title="Custom Plan"
            subtitle="For high-traffic websites
            All features in the growth plan with additional custom features available tailored to your needs."
            isCustomPlan={true}
          />
          <CustomPricingCard
            title="One-Time CRO Audit"
            subtitle="Detailed performance analysis"
            isCustomPlan={true}
          />
          <CustomPricingCard
            title="Web Implementation Services"
            subtitle="Charged hourly"
            isCustomPlan={true}
          />
        </Col>
      </Row>
    </PricingWrapper>
  );
}
