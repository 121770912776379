import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

// Layout
import AppLayout from '../../../layouts/AppLayout/AppLayout';

// Components
import { NioSection, NioField, NioBadge, NioMedia, NioCard } from '../../../components';

function Index() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('/api/medium-posts');
        if (!response.ok) throw new Error('Failed to fetch posts');
        const data = await response.json();
        setPosts(data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <AppLayout rootClass="layout-11">
      <NioSection className="overflow-hidden pt-120 pt-lg-160">
        <NioSection.Content>
          <Row className="justify-content-center text-center">
            <Col lg={8} xl={6}>
              <div className="nk-section-head">
                <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2">Our blog</span>
                <h2>Resource Center</h2>
                <p className="fs-20">Unlock the potential of our resource center, accessing valuable information and insights for your business growth.</p>
              </div>
            </Col>
            <Col lg={8}>
              <div className="nk-filter-wrap pb-5 pb-md-7">
                <NioField.Input icon="search before z-1" placeholder="Search for articles" />
              </div>
            </Col>
          </Row>
          <Row>
            {posts.map(post => (
              <Col md={6} lg={4} key={post.id}>
                <NioCard>
                  <NioCard.Body>
                    <div className="card-image">
                      {post.virtuals.previewImage.imageId && (
                        <img
                          src={`https://cdn-images-1.medium.com/max/800/${post.virtuals.previewImage.imageId}`}
                          alt={post.title}
                          className="card-img"
                        />
                      )}
                    </div>
                    <div className="card-content pt-4">
                      <NioBadge rounded className="text-bg-primary-soft mb-2 mb-md-3" label="Growth" />
                      <h5 className="text-capitalize m-0">
                        <Link className="text-dark" to={`/blog-details/${post.uniqueSlug}`}>{post.title}</Link>
                      </h5>
                      <div className="media-group pt-4 align-items-center">
                        {post.author.imageId && (
                          <NioMedia size="md" rounded img={`https://cdn-images-1.medium.com/fit/c/100/100/${post.author.imageId}`} />
                        )}
                        <div className="media-text">
                          <span className="lead-text fw-normal">{post.author.name}</span>
                          <ul className="nk-list-meta smaller">
                            <li>{new Date(post.createdAt).toLocaleDateString()}</li>
                            <li>{post.virtuals.subtitle.slice(0, 100)}...</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </NioCard.Body>
                </NioCard>
              </Col>
            ))}
          </Row>
        </NioSection.Content>
      </NioSection>
    </AppLayout>
  );
}

export default Index;
